/*
=============== 
Variables
===============
*/

:root {
  /* COLORS */

  --cogebisco-one: #9a261e;
  --cogebisco-two: #8d6047;
  --cogebisco-three: #cb8b69;
  --cogebisco-four: #2e64b0;
  --cogebisco-five: #a23e3c;

  --primary-chocolate: #d87d4a;
  --secondy-chocolate: #fbaf85;

  --primary-blackish: #101010;
  --actual-black: #000000;

  --primary-gray: #f1f1f1;
  --seondary-gray: #fafafa;
  --actual-white: #fff;

  /* dark shades of primary color*/
  --clr-primary-1: #1e1b4b;
  --clr-primary-2: #312e81;
  --clr-primary-3: #4338ca;
  --clr-primary-4: #4f46e5;
  /* primary/main color */
  --clr-primary-5: #6366f1;
  /* lighter shades of primary color */
  --clr-primary-6: #818cf8;
  --clr-primary-7: #a5b4fc;
  --clr-primary-8: #c7d2fe;
  --clr-primary-9: #e0e7ff;
  --clr-primary-10: #eef2ff;
  /* darkest grey - used for headings */
  --clr-grey-1: #00040c;
  --clr-grey-2: #000307;
  --clr-grey-3: #334155;
  --clr-grey-4: #475569;
  /* grey used for paragraphs */
  --clr-grey-5: #64748b;
  --clr-grey-6: #94a3b8;
  --clr-grey-7: #cbd5e1;
  --clr-grey-8: #e2e8f0;
  --clr-grey-9: #f1f5f9;
  --clr-grey-10: #f8fafc;
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Khand', sans-serif;
  background: white;
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
/* ul {
  list-style-type: none;
} */
a {
  text-decoration: none;
}
hr {
  border: none;
  border-top: 1px solid var(--clr-grey-8);
}
h1 {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h2,
h3,
h4,
h5 {
  font-family: 'Khand', sans-serif;
  letter-spacing: var(--spacing);
  /* text-transform: capitalize; */
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 0.875rem;
}
p,
li {
  margin-bottom: 1.25rem;
  color: black;
}

button {
  font-family: inherit;
}

/* .background-hero, */
.background-header {
  background: whitesmoke;
}

.background-hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.637), rgba(0, 0, 0, 0.637)),
    url('./assets/gegobisco/image/4.png');

  /* background-image: url('./assets/gegobisco/gavel-free.jpg'); */
  /* background-image: url('./assets/gegobisco/gavel-2.jpg); */
  /*background-image: url('./assets/gegobisco/gavel-with-balace.jpg);  */
  /*background-image: url('./assets/gegobisco/test-1.jpg')  */
  /*background-image: url('./assets/gegobisco/test-2.jpg')  */
  /*background-image: url('./assets/gegobisco/test-3.jpg')  */
  /*background-image: url('./assets/gegobisco/test-5.jpg')  */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 80px;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
.text-center {
  text-align: center;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: var(--clr-primary-5);
  animation: spinner 0.6s linear infinite;
}

.btn {
  text-transform: uppercase;
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  /* border-radius: var(--radius); */
  border-color: transparent;
  color: var(--actual-white);
  background: var(--cogebisco-three);
  border-radius: 5px;
}
.btn:hover {
  background: var(--cogebisco-five);
}

.primary-btn {
  padding: 1rem 2rem;
  color: var(--actual-white);
  background: var(--cogebisco-one);
}

.primary-btn:hover {
  background: var(--cogebisco-five);
}

.secondary-btn {
  padding: 1rem 2rem;
  color: var(--actual-black);
  background: transparent;
  border: 1px solid #000;
}
.secondary-btn:hover {
  color: var(--actual-white);
  background: var(--actual-black);
}

.third-btn {
  padding: 1rem 2rem;
  color: var(--actual-white);
  background: var(--actual-black);
}

.third-btn:hover {
  color: var(--actual-white);
  background: var(--primary-blackish);
}

.title {
  text-align: center;
}
.title .underline {
  width: 6rem;
  height: 0.25rem;
  background: #49a6e9;
  background: var(--primary-chocolate);
  margin-left: auto;
  margin-right: auto;
}
.page-100 {
  min-height: calc(100vh - 10rem);
  padding: 5rem 0;
}
.page {
  min-height: calc(100vh - (20vh + 10rem));
}
